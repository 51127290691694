/* .css-1bybzjd-MuiTableCell-root{
    background-color: rgb(244 244 255);     
} */

/* 
.MuiInputLabel-root {
    font-size: 14px !important; 
} 
*/

/* .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 14px !important; 
} */

/* .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
    font-size: 14px !important; 
} */


.MuiOutlinedInput-input {
    padding-top: 8px !important; 
    padding-right: 10px;
    padding-bottom: 5px  !important; 
    padding-left: 10px;
}

.MuiSelect-select{
    font-size: 13px !important; 
}
/* 
.MuiTypography-root{
    font-size: 10px !important;    
} */

.dropzone {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
  /* justify-content: center; */
  border: 3px dashed #bdbdbd;
  background-color: #fafafa;
  color: #bdbdbd;
  width: 250px;
  height: 150px;

  margin-bottom: 20px;
  cursor: pointer;
}
.accept {
  border-color: #107c10 !important;
}
.reject {
  border-color: #d83b01 !important;
}


.dropzoneFile {
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    /* justify-content: center; */
    /* border: 1px dashed #9a98f5; */
    background-color: #fafafa;
    color: #bdbdbd;
    /* width: 250px;
    height: 150px; */
    margin-bottom: 10px;
    cursor: pointer;
}
.accept {
  border-color: #107c10 !important;
}
.reject {
  border-color: #d83b01 !important;
}
  

.input-file-button{
  /* display: flex; */
  /* flex-direction: column; */
  padding: 5px; 
  background-color:#6071f1;
  border-radius: 5px;
  /* border: 100px; */
  color: white;
  /* cursor: pointer;   */
  width: 100px;
  height: 32px;  
  text-align: center;
}

.input-file-name{
  padding: 5px; 
  /* background-color:#eaecf7; */
  border-radius: 1px;
  /* border: 100px; */
  /* color: white; */
  /* cursor: pointer;   */
  width: 200px;
  height: 32px;  
  text-align: left;
}

.tablecell_customer_row{
  font-weight: bold;
  text-align: right;
}

.tablecell_base_sale{
  background-color: #f5f6fa;
  /* border-bottom-color: #1d701d; */
  width: 100px;
  min-width: 100px;
}


.tablecell_baseElectroinc{
  background-color: #f5f6fa;
  border-bottom-color: #1d701d;
  width: 140px;
  min-width: 140px;
}
.tablecell_base{
  background-color: #f5f6fa;
  /* border-bottom-color: #1d701d; */
  width: 140px;
  min-width: 140px;
}
