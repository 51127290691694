.ag-row .ag-cell {
  display: flex;
  /*justify-content: center; /* align horizontal */
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}
.ag-header-cell-label {
  justify-content: center;
}
.ag-header-group-cell {
  font-size: 14px;
  font-weight: normal;
}
.ag-header-group-cell-label {
  justify-content: center;
}
.ag-header-green {
  background-color: green;
  /* color: white; */
}
.ag-header-font-blue {
  color: blue;
  /* font-weight: bold; */
}

.ag-header-font-blue-desktop {
  color: blue;

  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: rgb(212, 212, 212);

  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-height: 25%;
  --ag-header-column-resize-handle-width: 0px;
  --ag-header-column-resize-handle-color: orange;
  border-top: 2px solid rgb(5, 5, 5);  
  border-bottom: 1px solid rgb(35, 34, 34); 
  font-size:15px; 
  padding: 0 !important;  

  /* --ag-header-column-font-size:'15px'; */
  align-content: center;
  justify-content: center;
}

.ag-header-font-mobile {
  font-size:12px;
}
.ag-header-cell.ag-header-cell-sortable.ag-header-font-mobile.ag-focus-managed{
  padding-left: 5px;
  padding-right: 5px;
}
.ag-header-cell {
  padding-left: 5px  !important;  
  padding-right: 5px  !important;  
}
.ag-header-font-desktop {
  /* font-size:15px; */
  
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: rgb(212, 212, 212);

  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-height: 25%;
  --ag-header-column-resize-handle-width: 0px;
  --ag-header-column-resize-handle-color: orange;
  
  border-top: 2px solid rgb(5, 5, 5);  
  border-bottom: 1px solid rgb(35, 34, 34); 
  font-size:15px; 
  padding: 0 !important;  

  /* --ag-header-column-font-size:'15px'; */
  align-content: center;
  justify-content: center;
}
.ag-header-group-text{
  font-weight: 600;
}
.ag-header-align {  
  align-content: center;
  justify-content: center;
  /* font-weight: bold; */
}
.ag-header-green2 {
  background-color: rgb(88, 240, 88);
  /* color: white; */
}
.ag-header-yellow {
  background-color: rgb(212, 243, 36);
  /* color: white; */
}
.ag-header-cell-label .ag-header-cell-text {
  white-space: pre-wrap !important;  
}

.custom-cell-renderer {
  white-space: normal;
  width: 100%; 
  /* font-size: 14px; */
  /* border: 1px solid grey; */
  /* border-style: ridge; */
  /* box-sizing: border-box; */
  /* background-color: rgb(236, 245, 186);  */
  text-align: center;
  margin: 0px;
  padding: 0px; 
  line-height: 1.5;
}

/*paging */
.ag-paging-panel {
  font-size: 12px;
}
.ag-paging-page-summary-panel,
.ag-paging-page-summary-panel select {
  font-size: 12px; 
}
/* 모바일에서만 적용되는 스타일 */
@media screen and (max-width: 767px) {
  .ag-paging-panel {
    font-size: 11px; /* 모바일 폰트 크기 */
    height: 30px; /* 모바일 높이 */
  }
  .ag-unselectable {
    font-size: 11px; /* 모바일 폰트 크기 */
    height: 30px; /* 모바일 높이 */
  }
  .ag-paging-panel {
    font-size: 11px; /* 모바일 폰트 크기 */
    height: 30px; /* 모바일 높이 */
  }
  .ag-paging-page-summary-panel,
  .ag-paging-page-summary-panel select {
    font-size: 11px; /* 모바일 폰트 크기 */
    height: 30px; /* 모바일 높이 */
  }
}
.ag-selection-checkbox{
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.cell-span {/*rowSpan*/
  background-color: #f8f8fd;  
  /* border-left: 1px solid var(--ag-foreground-color) !important; */
  border-right: 0.5px solid #d0cfcf !important;
  /* border-bottom: 1px solid var(--ag-foreground-color) !important; */
}
.row-wrapper {
  display: flex; /* 플렉스 박스 사용 */
  flex-direction: row; /* 행 방향으로 배치 */
  justify-content: space-between; /* 요소 사이의 간격을 최대로 설정하여 요소를 배치 */
  align-items: center; /* 요소를 수직으로 가운데 정렬 */
}

