.dashboard_card{
    background-color:#f7f8f9;
    margin-bottom: 1px;
}
.dashboard_Title_text{
    /* font-size: large; */
    /* font-size:larger; */
    font-size: 0.8em;
    text-align: left;
}
.dashboard_report_body_text{
    /* font-weight: bold; */
    font-size: 2.6em;
    text-align: center;
}
.dashboard_report_body_number{
    /* font-weight: bold; */
    font-size: 1.4em;
    text-align: center;
    color: green;
}
.dashboard_StyledTableCell{
    padding-top: '1px';
    padding-bottom: '1px';
}
.dashboard_mobile_button_text{
    /* font-size: large; */
    /* font-size:larger; */
    font-size: 0.7em;
    text-align: center;
}